import React, { FC } from 'react';
import { Container, Row, Col } from 'gatsby-theme-husky/src/layout';
import Button from 'gatsby-theme-husky/src/common/Button';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import classNames from 'classnames';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';

import { InfoBannerProps } from 'gatsby-theme-husky/src/components/InfoBanner/model';
import 'gatsby-theme-husky/src/components/InfoBanner/InfoBanner.scss';
import 'gatsby-theme-husky/src/components/InfoBanner/InfoBannerExtends.scss';

const InfoBanner: FC<InfoBannerProps> = ({
  title,
  description,
  button: { ariaLabel, buttonText, buttonLink },
  bannerImage,
  background,
  mobileBackground,
  isRightAligned,
}) => {
  const { isMobile } = useScreenRecognition();
  const backgroundImage = isMobile ? mobileBackground : background;

  return (
    <div
      className={classNames('info-banner', {
        'info-banner__right': isRightAligned,
      })}
    >
      <div className="info-banner__background">
        {backgroundImage?.gatsbyImage?.childImageSharp ? (
          <GatsbyImage
            alt={backgroundImage?.altText}
            fluid={backgroundImage?.gatsbyImage?.childImageSharp?.fluid}
          />
        ) : null}
      </div>
      <Container className="info-banner__content">
        <Row>
          <Col className="info-banner__text-container" sm="12" md="6">
            {title ? <h2 className="info-banner__title">{title}</h2> : null}
            {description ? <p className="info-banner__description">{description}</p> : null}
            {buttonText ? (
              <div className="info-banner__button">
                <Button
                  className="button--always-dark"
                  key={buttonText}
                  link={buttonLink?.[0].url}
                  ariaLabel={ariaLabel}
                >
                  {buttonText}
                </Button>
              </div>
            ) : null}
          </Col>
          <Col className="info-banner__image-container" sm="12" md="6">
            <div className="info-banner__image-wrapper">
              {bannerImage?.gatsbyImage?.childImageSharp ? (
                <GatsbyImage
                  alt={bannerImage?.altText}
                  fluid={bannerImage?.gatsbyImage?.childImageSharp?.fluid}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InfoBanner;
