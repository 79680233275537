import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Container, Layout, Row, Col } from 'gatsby-theme-husky/src/layout';
import ReviewsPanel from 'gatsby-theme-husky/src/components/ReviewsPanel';
import FullWidthPromo from 'gatsby-theme-husky/src/components/FullWidthPromo';
import TwoColumnPromo from 'gatsby-theme-husky/src/components/TwoColumnPromo';
import RelatedArticles from 'gatsby-theme-husky/src/components/RelatedArticles';
import RelatedProducts from 'gatsby-theme-husky/src/components/RelatedProducts';
import InfoBanner from 'gatsby-theme-husky/src/components/InfoBanner';
import breakpoints from 'gatsby-theme-husky/src/components/RelatedArticles/constants';
import { IRelatedProductsProps } from 'gatsby-theme-husky/src/components/RelatedProducts/models';
import { HomePageComponentPropsType } from 'gatsby-theme-husky/src/templates/HomePage/models';
import HomePageView from 'gatsby-theme-husky/src/components/HomePageView';
import 'gatsby-theme-husky/src/templates/HomePage/HomePageMain.scss';
import 'gatsby-theme-husky-secondary/src/gatsby-theme-husky/templates/HomePage/HomePageExtends.scss';

const WithContainer = ({ children }) => (
  <Container className="home-page__body-container">
    <Row>
      <Col sm="12" md="12" lg="12" xl="12">
        {children}
      </Col>
    </Row>
  </Container>
);

const elements = {
  'Full Width Promo': ({ properties: { title, cta, bodyColor, body } }, keyId) => (
    <WithContainer key={keyId}>
      <FullWidthPromo title={title} cta={cta} bodyColor={bodyColor?.[0]} body={body} />
    </WithContainer>
  ),
  'Two Column Promo': ({ properties: { promoRows } }, keyId) => (
    <TwoColumnPromo key={keyId} rows={promoRows} inFirstViewport={false} />
  ),
  'Related articles': (
    {
      properties: { relatedArticles, relatedArticlesTitle, relatedArticlesReadMore, articleLinks },
    },
    keyId
  ) => (
    <Container className="home-page__body-container" key={keyId}>
      <RelatedArticles
        breakpoints={breakpoints}
        data={
          articleLinks
            ? relatedArticles.nodes.filter(({ url }) => articleLinks.includes(url))
            : relatedArticles.nodes
        }
        title={relatedArticlesTitle}
        readMoreText={relatedArticlesReadMore}
        ariaLabel={relatedArticlesReadMore}
        articlesCount={3}
      />
    </Container>
  ),
  'Related Products': ({ properties: { relatedProducts, productLinks, ...restProps } }, keyId) => (
    <Container className="home-page__body-container" key={keyId}>
      <RelatedProducts
        relatedProducts={
          productLinks
            ? { nodes: relatedProducts.nodes.filter(({ url }) => productLinks.includes(url)) }
            : relatedProducts
        }
        {...(restProps as Omit<IRelatedProductsProps, 'relatedProducts'>)}
      />
    </Container>
  ),
  'Info Banner': (
    {
      properties: {
        infoBannerTitle,
        infoBannerDescription,
        infoBannerButton,
        infoBannerBgr,
        infoBannerImage,
        infoBannerMobileBgr,
        isBannerDefaultAligned,
      },
    },
    keyId
  ) => (
    <Container fluid className="info-container" key={keyId}>
      <InfoBanner
        title={infoBannerTitle}
        description={infoBannerDescription}
        button={infoBannerButton?.length ? infoBannerButton[0]?.properties : null}
        bannerImage={infoBannerImage}
        background={infoBannerBgr}
        mobileBackground={infoBannerMobileBgr}
        isRightAligned={isBannerDefaultAligned ? Boolean(Number(isBannerDefaultAligned)) : false}
      />
    </Container>
  ),
};

const ReviewsPanelRenderer = (
  {
    properties: { yotpoReviewsCarouselCode, yotpoReviewsCarouselTitle, yotpoReviewsCarouselButton },
  },
  keyId
) => (
  <ReviewsPanel
    key={keyId}
    yotpoReviewsCarouselTitle={yotpoReviewsCarouselTitle}
    yotpoReviewsCarouselCode={yotpoReviewsCarouselCode}
    yotpoReviewsCarouselButton={yotpoReviewsCarouselButton}
  />
);

const HomePage: FC<HomePageComponentPropsType> = ({
  data: {
    umbracoHome: {
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoAdditionalMeta,
      seoExternalHreflangs,
      defaultCompositions,
      hpCarousel,
      disclaimerDesktopImage,
      disclaimerMobileImage,
      hpCarousel: [
        {
          properties: {
            image: { gatsbyImage },
            imageAlt,
            title,
            description,
            button,
            imageMobile,
            bannerTheme,
          },
        },
      ],
      homepageBlocks,
    },
    relatedArticles,
    relatedProducts,
  },
}) => {
  const [bodyStructure, setBodyStructure] = useState(elements);
  const {
    commonSettings: { showReviewPanel },
  } = defaultCompositions;
  const isReviewPanelVisible = !!Number(showReviewPanel);

  useEffect(() => {
    if (isReviewPanelVisible) {
      const addReviewsPanelBlock = () => {
        const updatedElements = {
          ...bodyStructure,
          'Reviews Carousel': ReviewsPanelRenderer,
        } as typeof elements;

        setBodyStructure(updatedElements);
      };

      ['touchmove', 'scroll'].forEach((event) =>
        document.addEventListener(event, addReviewsPanelBlock, false)
      );

      return () => {
        ['touchmove', 'scroll'].forEach((event) =>
          document.removeEventListener(event, addReviewsPanelBlock)
        );
      };
    }
  }, [isReviewPanelVisible]);

  return (
    <Layout
      {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
      headerTransparent
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoExternalHreflangs,
        seoAdditionalMeta,
      }}
    >
      <HomePageView
        {...{
          hpCarousel,
          gatsbyImage,
          imageAlt,
          title,
          description,
          button,
          bannerTheme,
          homepageBlocks,
          bodyStructure,
          relatedArticles,
          relatedProducts,
          ...(imageMobile?.gatsbyImage && { imageMobile: imageMobile.gatsbyImage }),
        }}
      />
    </Layout>
  );
};

export const query = graphql`
  query(
    $url: String
    $relatedArticlesLinks: [String]
    $relatedProductsLinks: [String]
    $lang: String
  ) {
    umbracoHome(url: { eq: $url }, lang: { eq: $lang }) {
      seoMetaDescription
      seoMetaKeywords
      seoMetaTitle
      seoAdditionalMeta {
        key
        value
      }
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      hpCarousel {
        properties {
          title
          description
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageMobile {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 700, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageSm: image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          imageMd: image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          imageAlt
          button {
            properties {
              ariaLabel
              buttonText
              buttonLink {
                url
              }
            }
          }
          description
          bannerTheme
        }
      }
      homepageBlocks {
        structure
        properties {
          cta {
            properties {
              ariaLabel
              buttonColor
              buttonText
              buttonLink {
                url
                name
              }
            }
          }
          productLinks
          relatedProductsTitle
          relatedProductsCardCTAButtonAriaLabel
          relatedProductsCardCtaButton
          relatedProductsCtaButtonShow
          products {
            url
          }
          relatedProductsCtaButton {
            url
            name
          }
          infoBannerTitle
          infoBannerDescription
          isBannerDefaultAligned
          infoBannerButton {
            properties {
              ariaLabel
              buttonText
              buttonLink {
                name
                url
              }
            }
          }
          infoBannerImage {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 700, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          infoBannerMobileBgr {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 700, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          infoBannerBgr {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          articleLinks
          relatedArticlesReadMore
          relatedArticlesTitle
          title
          bodyColor
          body
          ...FragmentReviewsPanel
          promoRows {
            properties {
              title
              textBgColor
              packshotBgColor
              button {
                properties {
                  ariaLabel
                  buttonText
                  buttonLink {
                    name
                    url
                  }
                }
              }
              description
              imageBackground {
                properties {
                  imageAltLabel
                  image {
                    ...FragmentGatsbyImageLg
                  }
                }
              }
              imagePackshot {
                properties {
                  imageAltLabel
                  image {
                    ...FragmentGatsbyImageSm
                  }
                }
              }
              bGVersion
            }
          }
        }
      }
    }
    relatedProducts: allUmbracoProducts(filter: { url: { in: $relatedProductsLinks } }) {
      nodes {
        url
        properties {
          image {
            properties {
              imageAltLabel
            }
            localImage {
              childImageSharp {
                fluid(
                  maxWidth: 380
                  quality: 90
                  sizes: "(max-width: 767px) 50vw, (max-width: 991px) 178px, (max-width: 1199px) 334px, 283px"
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
        }
      }
    }
    relatedArticles: allUmbracoArticles(filter: { url: { in: $relatedArticlesLinks } }) {
      nodes {
        id
        title
        properties {
          title
          articleBody {
            structure
            properties {
              articleIntroductionText
            }
          }
          articleHeroBannerMainImageAltText
        }
        url
        localImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
